* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Variables de colores. */
:root {
  --main-verde: #16db65;
  --main-azul: #023047;
  --main-rojo: #a5352a;
  --main-ligth-color: #fff;
  --main-bg-color: var(--main-rojo);
  --main-title-color: var(--main-bg-color);
  --main-text-color: var(--main-azul);
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Iconos importados */
.icon-check-circle:before {
  content: "\e92c";
}

.icon-credit-card:before {
  content: "\e951";
}

.icon-menu:before {
  content: "\e998";
}

.icon-search:before {
  content: "\e9cc";
}

.icon-trash-2:before {
  content: "\e9f1";
}

.icon-x-circle:before {
  content: "\ea12";
}
input,
select,
textarea {
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: 1px solid #5e5e5e3b;
  font-family: 'Roboto', 'Lexend';
  font-size: 15px;
  font-weight: 500;
  &:focus {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
}

label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", 'Lexend';
  margin: 0;
}

button {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", 'Lexend';
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--main-rojo);
  font-family: "Lexend", 'Roboto';
}

p,
ul,
li {
  text-wrap: pretty;
  font-size: 15px;
  font-family: 'Roboto', 'Lexend';
  font-weight: 500;
  margin: 0;
}

a {
  color: black;
  font-size: 15px;
  font-family: 'Roboto', 'Lexend';
  font-weight: 600;
}

.skeleton-effect {
  animation: skeleton-loading 1s linear infinite alternate;
}

.invalid-message {
  font-size: 13px;
  font-family: 'Roboto', 'Lexend';
  font-weight: 700;
  color: rgb(190, 13, 13);
}

.btn-disable {
  pointer-events: none;
  opacity: .9;
}

.bold {
  font-weight: bold;
}

.button-a {
  backface-visibility: hidden;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  height: 35px;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  border: transparent;
  background: var(--main-rojo);
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 75%);
  }
  50% {
    background-color: hsl(200, 20%, 85%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

